import React, { useState, useEffect } from 'react';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Contact from './Contact.js';
import Modal1 from './modal1.js';
import './consola.css';
import image1 from '../dist/img/1.png';
import image2 from '../dist/img/2.png';
import image3 from '../dist/img/3.png';
import image4 from '../dist/img/4.png';
import image5 from '../dist/img/5.png';
import image6 from '../dist/img/6.png';
import image7 from '../dist/img/7.png';
import image8 from '../dist/img/8.png';
import image9 from '../dist/img/9.png';
import image10 from '../dist/img/10.png';
import image11 from '../dist/img/11.png';
import image12 from '../dist/img/12.png';
import image13 from '../dist/img/13.png';
import image14 from '../dist/img/14.png';
import image15 from '../dist/img/15.png';
import image16 from '../dist/img/16.png';
import image17 from '../dist/img/17.png';
import image17p from '../dist/img/17p.png';
import image11p from '../dist/img/11p.png';
import image11p2 from '../dist/img/11p2.png';
import image12p from '../dist/img/12p.png';
import image12p2 from '../dist/img/12p2.png';
import image13p from '../dist/img/13p.png';
import image13p2 from '../dist/img/13p2.png';
import image14p from '../dist/img/14p.png';
import image14p2 from '../dist/img/14p2.png';
import image15p from '../dist/img/15p.png';
import image15p2 from '../dist/img/15p2.png';
import ca from '../dist/img/cabeza.png';

import Gallery from './gallery.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faFacebook, faInstagram, faArtstation, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">

      <header className="App-header">


        <button className="menu-toggle" onClick={toggleMenu}>☰</button>

        <nav className={isMenuOpen ? 'nav-open' : ''}>
          <Link activeClass="active" to="nombre" spy={true} smooth={true} offset={-60} duration={500} onClick={toggleMenu}>Home</Link>
          <Link activeClass="active" to="skills" spy={true} smooth={true} offset={-60} duration={500} onClick={toggleMenu}>Skills</Link>
          <Link activeClass="active" to="aboutMe" spy={true} smooth={true} offset={-60} duration={500} onClick={toggleMenu}>About Me</Link>
          <Link activeClass="active" to="proyectos" spy={true} smooth={true} offset={-60} duration={500} onClick={toggleMenu}>Proyects</Link>
          
          <Link activeClass="active" to="contacto" spy={true} smooth={true} offset={-60} duration={500} onClick={toggleMenu}>Contact</Link>
        </nav>
      </header>

      <Element name="nombre" className="element img0">
        <div className="nameCen">
          <div className="nombre">
            <h1><span className="principal">PATRICIO</span><span className="principal2"> MOLINA ESCOBAR</span></h1>
            <h3>Software Engineer JavaScript Full-Stack</h3>
            <h4>Welcome to my world, a little space for creativity</h4>
          </div></div>

      </Element>

      <Element name="skills" className="element img1">
        <div className="skillsSection">
          <div className="skillsDescription">
            <h2>Skills</h2>
            <p>I concentrate my efforts on applying diverse technologies in the 
              creation of projects including desktop applications, web, mobile apps, and video games. I 
              am keen on exploring new methods to combine various software tools into a unified component. I am 
              convinced that digital projects reach their full potential when they incorporate programming, music, and design.</p>
          </div>
          <div className="skillsContainer">
            <img src={image1} alt="Skill 1" />
            <img src={image2} alt="Skill 2" />
            <img src={image3} alt="Skill 1" />
            <img src={image4} alt="Skill 1" />
            <img src={image5} alt="Skill 1" />
            <img src={image6} alt="Skill 1" />
            <img src={image7} alt="Skill 1" />
            <img src={image8} alt="Skill 1" />
            <img src={image9} alt="Skill 1" />
            <img src={image10} alt="Skill 1" />
            <img src={image11} alt="Skill 1" />
            <img src={image12} alt="Skill 2" />
            <img src={image13} alt="Skill 1" />
            <img src={image14} alt="Skill 1" />
            <img src={image15} alt="Skill 1" />
            <img src={image16} alt="Skill 1" />
          </div></div>
      </Element>

      <Element name="aboutMe" className="element img3">
        <div className='pa1'>
          <h2>About Me</h2>

          <div><img src={ca} alt="Skill 1" /></div>
          <p className='pa1'>
          I cherish the creative liberty to craft complete environments with unique elements through
          programming, music, and design. Personally, I strive to imbue each project with a 
          distinct perspective, thereby achieving an original and authentic outcome.</p></div>
      </Element>

      <Element name="proyectos" className="element img4">
        <div>
          <h2>Some Projects</h2>
          <h4>(Please feel free to use this code however you like)</h4>
        </div>
        <div className="projectsContainer">
          <div>E-commerce portal<h4>demo</h4>
            <div>

              <a href="https://storegitm1-production.up.railway.app/form-1" target="_blank" className="project">




                <img src={image11p} alt="Skill 1" />

              </a>
              <Modal1 description={<p className="overText">The project is developed using the MERN stack (MongoDB, Express, React, Node.js), serving as an e-commerce portal that
               facilitates parcel delivery and credit card payments. It offers versatile user registration options through email and social media, enhanced with Passport.js for robust authentication. Token-based 
               authentication ensures secure session management, complemented by a fully integrated shopping cart built in React. The system boasts strong data persistence, strict access controls, and real-time 
               functions for viewing and editing products. Payment processing is simplified and secured through Stripe. User registration is handled efficiently via email, Facebook, and Google APIs, all supported 
               by a comprehensive REST API for optimal scalability and integration.</p>} imgDes={image11p2} />
              <button className="elegant-button" onClick={() => window.open('https://github.com/Maze000/storeGitM1', '_blank')}>Github Code</button>


            </div></div>





          <div>Private Messenger<h4>demo</h4><div>
            <a href="https://messageg-production.up.railway.app/signup" target="_blank" className="project">
              <img src={image12p} alt="Skill 1" />
            </a>
            <Modal1 description={<p className="overText">This project is a private messenger developed using the MERN stack, utilizing the node-cron module for scheduling functionalities. It enables direct messaging to designated recipients and includes a feature for creating preset messages according to specific categories. Additionally, users can schedule messages to be sent automatically at predetermined times. This system not only enhances communication efficiency but also facilitates effective time management and message customization, catering to diverse user needs. The integration of node-cron adds a robust dimension to the application, enabling seamless automation of message dispatch based on user-defined schedules.
            </p>} imgDes={image12p2} />
            <button className="elegant-button" onClick={() => window.open('https://github.com/Maze000/privateMessage2', '_blank')}>Github Code</button>
          </div></div>



          <div>RPG Clicker Game New World Order<h4>demo</h4><div>
            <a href="https://maze000.github.io/newWorldOrder-rpg/" target="_blank" className="project">
              <img src={image13p} alt="Skill 1" /></a>
            <Modal1 description={<p className="overText">This project is a clicker parody game, fully developed in JavaScript, with a user-friendly interface that includes a button for accessing the main menu and gameplay modes. It offers three distinct levels of difficulty, each designed to test the player’s reflexes and clicking speed. Upon successfully completing all levels, players are rewarded with a final scene that provides a satisfying conclusion to the game. This game not only tests players’ abilities but also enhances their engagement with progressively challenging stages. Its seamless navigation and interactive design make it an enjoyable experience for users seeking entertainment.
            </p>} imgDes={image13p2} />
            <button className="elegant-button" onClick={() => window.open('https://github.com/Maze000/newWorldOrder-rpg', '_blank')}>Github Code</button>

          </div></div>




          <div>Web Scraper<h4>demo</h4><div>
            <a href="https://scrap1-production.up.railway.app/" target="_blank" className="project">
              <img src={image14p} alt="Skill 1" />
            </a>
            <Modal1 description={<p className="overText">This project is a web scraping tool designed to efficiently extract information from websites, utilizing React, Node.js, and the Cheerio library. It enables quick data gathering from various web pages, including images, titles, and prices. React enhances the user interface, making it responsive and dynamic, while Node.js underpins the backend, offering robust server-side processing. Cheerio provides a lightweight and flexible solution for parsing HTML, allowing effective access to and manipulation of webpage content. This tool is ideal for those looking to seamlessly aggregate data from multiple sources, thereby enhancing data analysis and digital marketing strategies. The integration of these technologies ensures a powerful and user-friendly experience for rapid information retrieval.
            </p>} imgDes={image14p2} />
            <button className="elegant-button" onClick={() => window.open('https://github.com/Maze000/scrap1', '_blank')}>Github Code</button>
          </div></div>



          <div>Engine Surface 2d beta<h4>demo</h4><div>
            <a href="https://drive.google.com/file/d/1eR7bxubBCj2yLHpiYvsW-tFKk4Pqr1LU/view?usp=drive_link" target="_blank" className="project">
              <img src={image15p} alt="Skill 1" />
            </a>
            <Modal1 description={<p className="overText">This advanced game development engine is built using Adobe Air and Adobe Flash, and utilizes ActionScript3 and C++. It is also equipped with motion-sensing technology, incorporating Microsoft Kinect, OpenKinect, and the Kinect for Windows SDK. The engine enhances its functionality with libraries such as OpenCV. It offers customizable game templates, enabling developers to modify features and gameplay to meet specific requirements. By integrating motion-sensing technology through Kinect, it provides an interactive gaming experience. This platform fosters creativity and innovation, equipped with a comprehensive set of tools designed for producing immersive video games.
            </p>} imgDes={image15p2} />
            <button className="elegant-button" onClick={() => window.open('https://drive.google.com/file/d/1eR7bxubBCj2yLHpiYvsW-tFKk4Pqr1LU/view?usp=drive_link', '_blank')}>Video Demo</button>
          </div></div>

          <div>Crypto Coins Checker<h4>demo</h4><div>
            <a href="https://coin2-production.up.railway.app/" target="_blank" className="project">
              <img src={image17} alt="Skill 1" /></a>
            <Modal1 description={<p className="overText">This project is a straightforward demo designed to fetch daily cryptocurrency records, developed using React for the frontend and Node.js for backend services. It retrieves data from the CoinGecko API, accessing various metrics for each coin. The frontend, built with React, offers a user-friendly interface that displays the cryptocurrency metrics in an easily digestible format, allowing users to view real-time updates and detailed analyses of each cryptocurrency's performance. This tool is especially useful for investors and enthusiasts looking to track market trends and make informed decisions based on up-to-date information. Together, React and Node.js ensure a robust, scalable solution for retrieving and presenting cryptocurrency data.
            </p>} imgDes={image17p} />
            <button className="elegant-button" onClick={() => window.open('https://github.com/Maze000/coin2', '_blank')}>Github Code</button>
          </div></div>
        </div>
      </Element>

      <Element name="gallery" className="element  img6">

        <div className='degrade'>
          <h2 className="galeryF">A Few Color</h2>
          <a href="https://www.artstation.com/moved_design-00" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faArtstation} size="2x" />
          </a>

          <Gallery />


        </div>
      </Element>

      <Element name="contacto" className="element img5">





        <Contact />
      </Element>

      <div className="App">
        <footer className='footer1' onClick={scrollToTop}>^</footer>

        <footer className="footer2">
          <div className="socialMedia">
            <a href="https://github.com/Maze000" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/patricio-molina-escobar-62571623b/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="https://www.artstation.com/moved_design-00" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faArtstation} size="2x" />
            </a>
            <a href="https://www.facebook.com/patriciomolinaescobar/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.instagram.com/patriciomolinae.artek/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
          <p className='footp'>&copy; 2024 <strong>mazeaction</strong>. All rights reserved.</p>
        </footer>
      </div>




    </div>
  );
};

export default App;











